<template>
    <section class="section-categorias-component py-2">
        <div class="row mx-0 position-relative">
            <!-- Arrows -->
            <div v-if="categoriasPrimeraLinea.lenght <= 7 || categoriasSegundaLinea.lenght <= 7" class="d-middle-center position-absolute cr-pointer bg-white rounded-circle shadow z-1" style="width:32px;height:32px;left:5px;top:36%;" @click="leftScroll">
                <i class="icon-left-open text-general f-20" />
            </div>
            <div v-if="categoriasPrimeraLinea.lenght <= 7 || categoriasSegundaLinea.lenght <= 7" class="d-middle-center position-absolute cr-pointer bg-white rounded-circle shadow z-1" style="width:32px;height:32px;right:50px;top:36%;" @click="rightScroll">
                <i class="icon-right-open text-general f-20" />
            </div>
            <div id="container" class="col px-0 overflow-auto custom-scroll">
                <div :class="`${dosLineas ? 'mb-3' : ''}`" style="display:inline-flex;">
                    <div v-for="(i, idx) in categoriasPrimeraLinea" :key="`slider-${idx}`" class="card-categoria br-6 mr-4 cr-pointer" @click="redireccionCategoria(i)">
                        <img :src="i.banner" width="100%" height="180px" class="bg-white obj-cover br-6" />
                        <p class="mt-2 nombre-categoria text-center f-16 text-general br-6 mt-2">
                            {{ i.nombre }}
                        </p>
                    </div>
                </div>
                <template v-if="dosLineas">
                    <div style="display:inline-flex;">
                        <div v-for="(i, idx) in categoriasSegundaLinea" :key="`slider2-${idx}`" class="card-categoria br-6 mr-4 cr-pointer" @click="redireccionCategoria(i)">
                            <img :src="i.banner" width="100%" height="180px" class="bg-white br-6" />
                            <p class="mt-2 nombre-categoria text-center f-16 text-general br-6 mt-2">
                                {{ i.nombre }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        data:{
            type:Object,
            default:() => {}
        },
    },
    computed:{
        ...mapGetters({
            isInvitado:'home/isInvitado',
        }),
        dosLineas(){
            if(this.data.tipo == 31){
                return false
            }
            return true
        },
        categorias(){
            return this.data.elementos.categorias
        },
        categoriasPrimeraLinea(){
            let categorias = []
            if(this.dosLineas){
                this.categorias.map((e, index) => {
                    let posicion = index + 1;
                    if((posicion % 2) != 0){
                        categorias = [...categorias, e];
                    } 
                });
            } else {
                return this.categorias
            }
            return categorias
        },
        categoriasSegundaLinea(){
            let categorias = []
            if(this.dosLineas){
                this.categorias.map((e, index) => {
                    let posicion = index + 1;
                    if((posicion % 2) == 0){
                        categorias = [...categorias, e];
                    }
                });
            } else {
                return []
            }
            return categorias
        }
    },
    methods: {
        redireccionCategoria(item){
            this.guardarClick(item.id_elemento)
            if(this.isInvitado){
                this.$router.push({path:'invitado' + item.link})
                return
            }
            this.$router.push({path:item.link})
        },
        async guardarClick(idElemento){
            this.$store.dispatch('home/guardarClick',idElemento)
        },
        leftScroll(){
            let container = document.getElementById('container');
            this.sideScroll(container,'left',25,100,25);
        },
        rightScroll(){
            let container = document.getElementById('container');
            this.sideScroll(container,'right',25,100,25);
        },
        sideScroll(element,direction,speed,distance,step){
            let scrollAmount = 0;
            var slideTimer = setInterval(function(){
                if(direction == 'left'){
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if(scrollAmount >= distance){
                    window.clearInterval(slideTimer);
                }
            }, speed);
        }
    }
}
</script>

<style lang="scss" scoped>
.card-categoria{
    width: 150px;
    height: 248px;
    .nombre-categoria{
        height: 58px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }
}

.break-line{
    flex-basis: 100%;
    height: 0;
}

</style>